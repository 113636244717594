// Vanilla Libraries
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'css.escape';
import Modernizr from 'modernizr'; // Import handled by modernizr-loader, so vendor.js must also have this line
import moment from 'moment';
import 'moment-timezone';
import 'magnify';
import 'mobile-detect';
import 'countup.js';
import 'waypoints/lib/noframework.waypoints';
import 'waypoints/lib/shortcuts/inview';

import {Swiper, Navigation, Pagination, Autoplay} from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);

import * as Sentry from '@sentry/browser';
Sentry.init({
  dsn: sentryDsn,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
  ],
});

// JQuery & Plugins
import 'jquery';
import 'jquery.mmenu';
import 'jquery.dotdotdot';

// Beamstyle - Jquery Plugins
import 'jquery.ajaxrest';
import 'jquery.ajaxrestform';
import 'jquery.infinitescroll';

var jlib = {
  myCore: {
    initialize: function() {
      jlib.myCore.dateTime.initialize();
    },
    dateTime: {
      initialize: function() {
        var dateTimeFormat = 'YYYY-MM-DD HH:mm:ss',
          browserDateTimeOnLoad = moment()
            .tz(myCore.dateTime.serverTimezone)
            .format(dateTimeFormat);

        myCore.dateTime.browserDateTimeClockDrift = moment(myCore.dateTime.serverDateTimeOnLoad, dateTimeFormat).diff(
          browserDateTimeOnLoad,
          'seconds',
        );

        function refreshServerDateTimeNow() {
          var browserDateTimeNow = moment()
              .tz(myCore.dateTime.serverTimezone)
              .format(dateTimeFormat),
            timeElapsedSinceLoad = moment(browserDateTimeNow, dateTimeFormat).diff(browserDateTimeOnLoad, 'seconds');

          myCore.dateTime.serverDateTimeNow = moment(myCore.dateTime.serverDateTimeOnLoad, dateTimeFormat)
            .add(timeElapsedSinceLoad, 'seconds')
            .format(dateTimeFormat);
          setTimeout(refreshServerDateTimeNow, 1000);
        }
        refreshServerDateTimeNow();
      },
      methods: {
        getServerTimezone: function() {
          return myCore.dateTime.serverTimezone;
        },
        getServerDateTimeOnLoad: function() {
          return myCore.dateTime.serverDateTimeOnLoad;
        },
        getServerDateTimeNow: function() {
          return myCore.dateTime.serverDateTimeNow;
        },
        getBrowserDateTimeClockDrift: function() {
          return myCore.dateTime.browserDateTimeClockDrift;
        },
      },
    },
  },
  helpers: {
    link: function(href, target) {
      target = target === undefined ? '_self' : target;
      window.open(href, target);
    },
    getFilenameWithoutExtension: function(filename) {
      return filename.replace(/\.[^/.]+$/, '');
    },
  },
  ajaxRest: {
    initialize: function() {
      // Form for "edit"
      $('.ajax-edit').ajaxRestForm({
        // Passing in the RESPONSES_BY_STATUS_CODES
        RESPONSES_BY_STATUS_CODES: RESPONSES_BY_STATUS_CODES,

        // Overriding some common callbacks.  We pass in "true" for recursive merge, and {} to ensure the objects we pass in do not get modified itself.
        callbacks: $.extend(true, {}, jlib.ajaxRest.commonCallbacks, {
          // Proper response callbacks
          success: function(jqXHR) {
            jlib.ajaxRest.commonCallbacks.success(jqXHR);
            var $form = $(this),
              successRedirectUrl = $form.data('success-redirect');
            jlib.helpers.link(successRedirectUrl);
          },
        }),
      });

      // Form for "create"
      $('.ajax-create').ajaxRestForm({
        // Passing in the RESPONSES_BY_STATUS_CODES
        RESPONSES_BY_STATUS_CODES: RESPONSES_BY_STATUS_CODES,

        // Overriding some common callbacks.  We pass in "true" for recursive merge, and {} to ensure the objects we pass in do not get modified itself.
        callbacks: $.extend(true, {}, jlib.ajaxRest.commonCallbacks, {
          // Proper response callbacks
          success: function(jqXHR) {
            jlib.ajaxRest.commonCallbacks.success(jqXHR);
            var $form = $(this),
              successRedirectUrl = $form.data('success-redirect');
            jlib.helpers.link(successRedirectUrl);
          },
        }),
      });
    },
    helpers: {
      displayErrors: function($form, errors) {
        $.each(errors, function(field, message) {
          var $field = $form.find('.field.' + CSS.escape(field, { isIdentifier: true })),
            $errorMessage = $field.find('.error-message');
          $errorMessage.text(message);
          $field.addClass('error');
        });
      },
    },
    commonCallbacks: {
      // Before AJAX request
      beforeSend: function(resolve, reject) {
        console.log('beforeSend');
        // $.LoadingOverlay('show');
        resolve();
      },

      // Connectivity error callbacks
      userAborted: function(jqXHR) {
        console.log('userAborted');
      },
      responseTimedOut: function(jqXHR) {
        console.log('responseTimedOut');
      },

      // Parser error callbacks
      parserError: function(jqXHR) {
        console.log('parserError');
      },

      // Proper response callbacks
      success: function(jqXHR) {
        console.log('success');
      },
      requestMalformed: function(jqXHR) {
        console.log('requestMalformed');
      },
      loginRequired: function(jqXHR) {
        console.log('loginRequired');
        var sessionTimedOutRedirectUrl =
          siteUrl + 'console/connect/login?redirectUrl=' + encodeURIComponent(currentRouteWithQueryString);
        jlib.helpers.link(sessionTimedOutRedirectUrl);
      },
      notEnoughAccess: function(jqXHR) {
        console.log('notEnoughAccess');
      },
      notFound: function(jqXHR) {
        console.log('notFound');
      },
      unsupportedFormat: function(jqXHR) {
        console.log('unsupportedFormat');
      },
      validationError: function(jqXHR) {
        var $form = $(this),
          data = jqXHR.responseJSON,
          errors = data.errors;
        jlib.ajaxRest.helpers.displayErrors($form, errors);
      },
      tooManyRequests: function(jqXHR) {
        console.log('tooManyRequests');
      },
      unexpectedError: function(jqXHR) {
        console.log('unexpectedError');
      },
      default: function(jqXHR) {
        console.log('default');
      },
    },
  },
};

$(document).ready(function(e) {
  jlib.myCore.initialize();
  jlib.ajaxRest.initialize();

  //magnify effect//
  $(document).ready(function() {
    $('.zoom').magnify();
  });

  // Initialize multiline truncate (ellipsis / clamp) effect
  $('.multiline-truncate').each(function() {
    var $text = $(this),
      maximumLines = parseInt($text.data('maximum-lines')),
      lineHeight = parseInt($text.css('line-height'));

    if (!isNaN(maximumLines)) {
      $text.css('max-height', lineHeight * maximumLines);
      $text.css('overflow', 'hidden');
    }

    $text.dotdotdot({
      ellipsis: '...',
      watch: 'window',
    });
  });


  // Simple element animation
  $('.animation').each(function() {
    var $this = $(this),
        repeatable = ($this.data('repeatable') !== undefined) ? $this.data('repeatable') : false,
        timer = null;

    var waypoint = new Waypoint.Inview({
        element: $this[0],
        enter: function () {
            // Timer set, just in case if a page gets reloaded in a position beyond the element, timer can be cleared out immediately as if the event has never happened.
            timer = setTimeout(function() {
                $this.addClass('animate');
            }, 200)
        },
        exited: function () {
            // Clearing any active timers.
            clearTimeout(timer)
            if( repeatable ) {
                $this.removeClass('animate');
            }
        }
    });
  });

  // Infinite Scroll
  $('.infinite-scroll').infiniteScroll({
    ajaxRestCallbacks: $.extend(true, {}, jlib.ajaxRest.commonCallbacks, {
      success: function(jqXHR) {
        jlib.ajaxRest.commonCallbacks.success(jqXHR);
        console.log('ajaxRestCallbacks.success', $(this), jqXHR);
      },
    }),
    callbackEntityAdded: function($entity) {
      console.log('callbackEntityAdded', $(this), $entity);
    },
  });

  // Block Carousel
  $('.carousel').each(function() {
    var $blockCarousel = $(this),
      $blockCarousel_swiper = $blockCarousel.find('.swiper-container'),
      $blockCarousel_pagination = $blockCarousel.find('.pagination'),
      $blockCarousel_navigation = $blockCarousel.find('.navigation'),
      $blockCarousel_navigation_buttonPrevious = $blockCarousel_navigation.find('.button-previous'),
      $blockCarousel_navigation_buttonNext = $blockCarousel_navigation.find('.button-next');

    var loop = $blockCarousel.data('loop'),
      autoplay = $blockCarousel.data('autoplay'),
      breakpoints = $blockCarousel.data('breakpoints');

    var swiperOptions = {
      direction: 'horizontal',
      loop: loop !== undefined ? loop : false,
    };

    // If autoplay is defined and not false
    if (autoplay !== undefined && autoplay) {
      swiperOptions = $.extend(swiperOptions, {
        autoplay: {
          disableOnInteraction: true,
          delay: parseInt(autoplay),
        },
      });
    }

    // If breakpoints is defined
    if (breakpoints !== undefined) {
      swiperOptions = $.extend(swiperOptions, {
        breakpoints: breakpoints[0],
      });
    }

    // If there are navigation elements (< and >)
    if ($blockCarousel_navigation.length) {
      swiperOptions = $.extend(swiperOptions, {
        navigation: {
          prevEl: $blockCarousel_navigation_buttonPrevious[0],
          nextEl: $blockCarousel_navigation_buttonNext[0],
        },
      });
    }

    // If there is a pagination element (bullets)
    if ($blockCarousel_pagination.length) {
      swiperOptions = $.extend(swiperOptions, {
        pagination: {
          el: $blockCarousel_pagination[0],
          clickable: true,
        },
      });
    }

    var mySwiper = new Swiper($blockCarousel_swiper[0], swiperOptions);
  });

  // Add "scroll-top" class when scroll is at top of page in the browser
  var $html = $('html'),
      offset = 59;
  $(document).scroll(function(){
    if( $(this).scrollTop() <= offset ) {
      $html.removeClass('scroll-not-top');
      $html.addClass('scroll-top');
    } else {
      $html.removeClass('scroll-top');
      $html.addClass('scroll-not-top');
    }
  }).trigger('scroll');

  // Mobile Menu
  $('#mobile-nav').mmenu({
    navbar: {
        title: ''
    },
    offCanvas: {
        position: 'right'
    },
    extensions: [
        "fx-menu-fade",
    ]
  });

  // Google Maps
  $('.map').each(function(){
    var $this = $(this),
        el = $this.get(0),
        latitude = $this.data('latitude'),
        longitude = $this.data('longitude'),
        zoom = $this.data('zoom'),
        pinUrl = $this.data('pin-url');

    var map = new google.maps.Map(el, {
        center: {lat: latitude, lng: longitude},
        zoom: zoom,
        scrollwheel: false
    });

    var marker = new google.maps.Marker({
        position: {lat: latitude, lng: longitude},
        map: map,
        icon: pinUrl
    });
  });

  // Click event to scroll to top
  $('.scroll-to-top').click(function(e){
    e.preventDefault();
    $('html, body').animate({scrollTop: 0}, 500);
  });

  // Count up
  $('.countup').each(function() {
    var $this = $(this),
        targetNumber = parseInt($this.text());

    var countUp = new CountUp($this[0], 0, targetNumber);
    if (!countUp.error) {
        countUp.start();
    }
  });

  // app.js loaded events (add class to HTML tag, send event to Google Tag Manager via dataLayer)
  var $html = $('html');
  $html.addClass('app-js-loaded');
  dataLayer.push({'event': 'app-js-loaded'});

  // Every time the window is scrolled //
  $(window).scroll( function(){
    /* Check the location of each desired element */
    $('.hideme').each( function(i){
      var bottom_of_object = $(this).position().top + $(this).outerHeight();
      var bottom_of_window = $(window).scrollTop() + $(window).height();
      /* If the object is completely visible in the window, fade it it */
      if( bottom_of_window > bottom_of_object ){
          $(this).animate({'opacity':'1'},1500);
      }
    });
  });
});
